.App {
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animation, .animation_layer {
	height: 1000px;
}

.animation {
  display: block;
	position: relative;
	z-index: 10;
}

.animation_layer {
	background-position: bottom center;
	background-size: auto 1038px;
	background-repeat: repeat-x;
	width: 100%;
	position: absolute;
}
.animation_layer.parallax {
	position: fixed;
}

#image1, #image2, #image3, #image4, #image5, #image6, #image7, #image8 {
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  min-height: 705vh;
  image-rendering: pixelated;
}

#image1 {
    background-image: url(./assets/BG1.png);
}

#image2 {
    background-image: url(./assets/BG2.png);
}

#image3 {
    background-image: url(./assets/BG3.png);
}

#image4 {
    background-image: url(./assets/BG4.png);
}

#image5 {
    background-image: url(./assets/BG5.png);
}

#image6 {
    background-image: url(./assets/BG6.png);
}

#image7 {
    background-image: url(./assets/BG7.png);
}

#image8 {
    background-image: url(./assets/BG8.png);
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
}

.bouncing {
  animation: bounce 1s infinite;
}

.flavortext2 {
  position: fixed;
  top: 570vh;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .flavortext2 {
    transform: translate(-50%, -50%) scale(0.8);
    top: 570vh;
  }
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(1turn);
  }
}

.Token {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-width: 768px) {
  .Token {
    transform: scale(0.7);
  }
  .Disclaimer .disclaimerText {
    font-size: 4px; /* Adjust as needed */
  }
}

.Disclaimer {
  position: fixed;
  top: -240%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%; /* Adjust as needed */
  text-align: center;
}

.disclaimerText {
  color: white;
  font-size: 8px;
  line-height: 2;
}

body {
  padding: 0;
}